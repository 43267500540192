@import '_theme';
@import '_common';

.userProfile {
  margin: 0 auto;
  padding-bottom: 30px;
  @extend %wrapper-padding;

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(4, [col] 1fr);
    grid-template-rows: repeat(2, [row] auto);
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    padding: 40px 6%;
    height: 100%;
  }
}

.profileHeaderWarningBanner {
  grid-row: row;
  grid-column: 1/6;
}

.profileBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px;

  &__imageWrapper {
    justify-self: center;
  }

  &__joinDate {
    margin-top: 1em;
    color: $mainFontColor;
  }

  &__editButtonWrapper {
    justify-self: center;
    padding-top: 57px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
    grid-template-rows: 50px 50px;
    button {
      height: 44px;
      min-width: 260px;
    }
  }

  &__editButtonDirSyncWrapper {
    justify-self: center;
    padding-top: 57px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
    button {
      height: 44px;
      min-width: 260px;
    }
  }
}

.divider {
  border-radius: 4px;
  background-color: whitesmoke;
  padding: 0.5px;
  margin-top: 1em;
  margin-bottom: 1em;
  height: 1.5px;
}

.profile-footer {
  grid-column: col 2 / span 4;
  grid-row: row 3 / 5;
  padding-left: 50px;
  padding-top: 15px;
}

.promoteAdminModal {
  max-height: 700px;
  padding: 0 1em;
  color: $mainFontColor;
  &__title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family: $nunito;
  }

  &__dropdownWrapper {
    margin-top: 1em;
    &--disabled {
      * {
        pointer-events: none;
      }
    }
    &__labelHelper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .MuiChip-outlined {
        border: 1px solid $mainTealColor;
        color: $mainTealColor;
      }
    }
  }

  &__buttonWrapper {
    display: flex;
    justify-content: space-between;
    text-align: right;
    margin-top: 1.5em;
  }
}
