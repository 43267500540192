@import '_theme';

$sidePanelWidth: 290px;
$sidePanelLeftPadding: 20px;
$userListMinWidth: 510px;
$userListContentRightPadding: 15px;
$userListContentLeftPadding: 50px;
$userBlockTemplateColumns: 0.4fr 2fr 1fr 1fr 0.4fr;

.noUser {
  font-size: 18px;
  margin-top: 40px;
  text-align: left;
}

/* SortButton */
.sortButtonHolder {
  position: relative;
  user-select: none;
  display: flex;
  padding: 12px 12px 12px $userListContentLeftPadding;

  & > :last-child {
    width: $sidePanelWidth;
  }

  .sortButtons {
    display: grid;
    font-size: 14px;
    width: 100%;
    min-width: $userListMinWidth;
    text-transform: uppercase;
    grid-template-columns: 0.4fr 1.2fr 1fr 1fr 0.4fr;
    color: $mainFontColor;
    grid-template-areas: 'sortUser . sortUserStatus sortJoinDate';

    %sort-shared {
      width: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        transform: translateY(1.5px);
      }

      svg {
        width: 1.2em;
        height: 1.2em;
      }
    }

    &__userStatus {
      cursor: unset !important;
      grid-area: sortUserStatus;

      span {
        padding-right: 2em;
      }

      @extend %sort-shared;
    }

    &__joinDate {
      grid-area: sortJoinDate;
      @extend %sort-shared;
    }

    &__user {
      grid-area: sortUser;
      justify-content: left !important;
      @extend %sort-shared;
    }
  }
}

/* UserList */
.usersList {
  &__wrapper {
    display: flex;
    padding-bottom: 30px;

    &__content {
      display: inline-block;
      border-radius: 4px;
      background: white;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
      padding-left: $userListContentLeftPadding;
      padding-right: $userListContentRightPadding;
      max-height: calc(100vh - 160px);
      width: 100%;
      min-width: $userListMinWidth;
      overflow: auto;
    }
  }
}

.usersListV2 {
  &__wrapper {
    display: flex;
    padding-bottom: 30px;

    &__content {
      display: inline-block;
      border-radius: 4px;
      background: white;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
      padding-left: $userListContentLeftPadding;
      width: 100%;
      min-width: $userListMinWidth;
    }
  }
}

.usersBlock {
  padding: 1em 1em 1em 0;
  border-bottom: 0.5px solid #97979743;
  display: grid;
  grid-template-columns: $userBlockTemplateColumns;
  grid-template-rows: 1fr auto auto;
  grid-template-areas:
    'avatarIcon userNames userNames userNames'
    'avatarIcon userTitle userStatus joinDate'
    'avatarIcon userTags userStatus joinDate';

  &__avatarIcon {
    grid-area: avatarIcon;
    padding-right: 1em;
    justify-self: left;
    display: flex;
    align-items: center;
  }

  &__userNames {
    $self: &;
    width: 100%;
    max-width: 100%;
    cursor: pointer;
    position: relative;
    grid-area: userNames;
    justify-self: left;
    display: flex;
    align-items: center;

    &--fullName {
      color: $mainFontColor;
      font-size: 22px;
      line-height: 30px;
      align-self: center;
      font-weight: 600;
      max-width: 50%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &--username {
      max-width: 50%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: $mainFontColor;
      padding-left: 0.5em;
      letter-spacing: -0.5px;
    }

    svg {
      fill: $mainFontColor;
      padding-top: 1px;
      text-decoration: underline;
      transition: all 200ms ease-out;
    }

    &:hover {
      #{$self}--fullName {
        color: $mainButtonColor;
      }

      #{$self}--username {
        color: $mainButtonColor;
      }

      svg {
        fill: $mainButtonColor;
        transform: translateX(3px);
      }
    }
  }

  &__userTitle {
    width: 100%;
    grid-area: userTitle;
    justify-self: left;
    display: flex;
    align-items: flex-start;
    text-align: left;
    max-height: 36px;
    overflow: hidden;
    font-size: 14px;
    color: #767676;
    letter-spacing: 0px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &__userTags {
    width: 100%;
    line-height: 1em;
    grid-area: userTags;
    text-align: left;
    user-select: none;
    text-transform: uppercase;

    .MuiChip-root {
      color: white;
      height: 24px;
      margin-right: 5px;
      margin-bottom: 5px;
      font-size: 12px;
      letter-spacing: 0.5px;
      border-radius: 5px;

      .MuiChip-label {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    .adminTag {
      background-color: $mainButtonColor;
    }

    .shellTag {
      background-color: darkgrey;
    }
  }

  &__userStatus {
    color: $mainFontColor;
    width: 100%;
    grid-area: userStatus;
    text-transform: capitalize;
    text-align: left;
  }

  &__joinDate {
    color: $mainFontColor;
    width: 100%;
    grid-area: joinDate;
    text-align: center;
  }

  &__pendingUserNames {
    $self: &;
    cursor: default !important;
  }
}

.force-overflow {
  min-height: 450px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #a8a8a8;
}
