@import '_theme';

.genericButton {
  color: $mainButtonColor;
  background-color: white;
  border: solid 1px $mainButtonColor;
  border-radius: 4px;
  padding-top: 13px;
  padding-bottom: 13px;
  font-size: 16px;
  width: 260px;
  font-weight: 700;
  height: 45px;
  letter-spacing: 0.01em;
  cursor: pointer;
  &:hover {
    color: white;
    background: darken($mainButtonColor, 8%);
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.genericButton--red {
  @extend .genericButton;
  background: $mainButtonColor;
  color: white;
  &:hover {
    background: darken($mainButtonColor, 8%);
  }
  &:disabled:hover {
    color: white;
    background: grey;
    border-color: grey;
  }
}

.react-select__menuList {
  border-radius: 6px;
  background-color: white;
  z-index: 9;
  // box-shadow: 0 1.5px 3.5px 0 rgba(0, 0, 0, 0.3);
  & > div {
    border-radius: 6px;
    will-change: unset !important;
  }
}

.react-select__optionLayout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
}

.react-select__menuList__notFound {
  height: 70px;
  font-size: 16px;
  padding-left: 30px;
  line-height: 70px;
  color: grey;
}

.close {
  cursor: pointer;
  box-sizing: content-box;
  position: absolute;
  right: 4px;
  top: 20px;
  width: 25px;
  height: 25px;
  padding: 15px;
  opacity: 0.3;
  &:hover {
    opacity: 1;
  }
  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 25px;
    width: 2px;
    background-color: #333;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

.userList__layout {
  $self: &;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 0 0.5em;
  .userList__userDescription {
    margin: 0 1em;
    #{$self}__userFullName {
      font-size: 0.8em;
      color: black;
    }
    #{$self}__userRole {
      font-size: 0.6em;
      color: $mainFontColor;
    }
  }
}

.successToastr {
  background: #00859a !important;
}
