@import '_theme';

.login__wrapper {
  width: 100vw;
  height: 100vh;
  background: url(../../assets/static/Back.png) no-repeat center center fixed;
  background-size: cover;

  &__central {
    width: 85%;
    min-width: 600px;
    max-width: 560px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.hypercare_logo {
  width: 50%;
  margin: auto;
  margin-bottom: 2em;
  max-width: 300px;
  object-fit: contain;
}

.loginForm {
  margin-top: 0;
}

.sso__btn__image {
  width: 3em;
  height: 1em;
  padding-right: 10px;
}

.loginDivider {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.7;
  margin: 1em 0;
  &:before {
    content: '';
    background: linear-gradient(to right, transparent, grey, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    padding: 0 0.5em;
    line-height: 1.5em;
    color: grey;
    background-color: white;
  }
}

.loginBox {
  padding: 30px;
  background: white;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-flow: column;
  border-radius: 20px;
  h1 {
    font-family: $openSans;
    padding-top: 15px;
    color: $mainButtonColor;
    text-align: center;
    font-size: 28px;
    margin: 0;
    margin-bottom: 1em;
  }
  .MuiOutlinedInput-input {
    padding: 14px !important;
    font-size: 14px;
  }

  &__error {
    color: $mainButtonColor;
    margin-top: 1em;
    a {
      font-weight: bold;
      color: inherit;
    }
  }
}
