$smallBreakPoint: 460px;
$mediumBreakPoint: 748px;
$largeBreakPoint: 968px; // TODO: $globalWidthBreakPoint for app-content min-width
$xlargeBreakPoint: 1500px;

@mixin respond_to($breakpoint) {
  @if $breakpoint == 'small' {
    @media only screen and (max-width: $smallBreakPoint) {
      @content;
    }
  } @else if $breakpoint == 'medium' {
    @media only screen and (max-width: $mediumBreakPoint) {
      @content;
    }
  } @else if $breakpoint == 'large' {
    @media only screen and (max-width: $largeBreakPoint) {
      @content;
    }
  } @else if $breakpoint == 'xlarge' {
    @media only screen and (min-width: $xlargeBreakPoint) {
      @content;
    }
  } @else {
    @media ($breakpoint) {
      @content;
    }
  }
}
