@import '_theme';

.instruction {
  color: $mainFontColor;
  margin-bottom: 0.5em;
}

.instruction-body {
  background: #f2f2f2;
  border-radius: 3px;
  padding: 16px;
}

.autoCompleteWrapper {
  max-height: 500px;
  overflow-y: auto;
  margin-top: 1em;
  padding-top: 10px;
  .MuiInputBase-root {
    align-items: flex-start !important;
    min-height: 6em !important;
  }
  .MuiAutocomplete-endAdornment {
    display: none !important;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
    min-width: 100px !important;
  }
  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding-right: 9px !important;
  }
}

.flexContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  color: $mainFontColor;
  p {
    margin-bottom: 0;
  }
}

.submitButton {
  display: block !important;
  margin: 1.5em auto 0 auto !important;
  width: 80%;
  height: 50px;
}

.errorMessage {
  color: red;
  font-size: 14px;
  margin: 0;
}
