@import '_theme';
@import '_common';

%dropdownToggler {
  display: flex;
  user-select: none;
  position: relative;
  cursor: pointer;
}

%dropdown-shared {
  list-style: none;
  padding: 0;
  z-index: 9;
  position: absolute;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
}

.userDropdownToggler {
  @extend %dropdownToggler;
  @extend %border-bottom-animation;
}

.organizationDropdownToggler {
  @extend %dropdownToggler;
}

.dropdownUserMenu {
  @extend %dropdown-shared;
  right: 0;
  margin-top: 50px;
  min-width: 130px;
  &__item {
    color: $mainFontColor;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    min-height: 40px;
    cursor: pointer;
    &:active {
      opacity: 0.5;
    }
    &:hover {
      color: black;
      background-color: #f2f2f2;
    }
  }
}

.dropdownOrganizationMenu {
  @extend %dropdown-shared;

  &__item {
    position: relative;
    color: $mainFontColor;
    font-size: 16px;
    line-height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
    min-height: 40px;
    cursor: pointer;
    &:active {
      opacity: 0.5;
    }
    &:hover {
      color: black;
      background-color: #f2f2f2;
    }
    &:first-of-type {
      border-top: none !important;
    }

    &--site {
      padding-left: 3em;
    }

    &--department {
      padding-left: 5em;
      &:last-of-type {
        margin-bottom: 10px;
      }
    }

    &--border {
      border-top: solid 0.5px silver;
      margin-top: 8px;
    }

    &--siteBorder {
      margin-bottom: 8px;
      &::after {
        margin-right: 3em;
        display: block;
        content: '';
        background: silver;
        height: 0.5px;
      }
    }
  }
  svg {
    float: right;
    stroke-width: 4px;
    width: 20px;
    height: 20px;
    margin-left: 8px;
  }
}

.organizationSwitcherDropdown {
  left: -100px;
  margin-top: 50px;
  max-height: 600px;
  overflow-y: auto;
  min-width: 500px;
  max-width: 800px;
  width: max-content;
}

.promoteAdminOrganizationDropdown {
  display: block;
  position: relative;
  max-height: 480px;
  overflow: auto;
  box-shadow: unset;
  border: 1px solid lightgrey;
  border-radius: 0;
  * {
    user-select: none;
  }
}
