%wrapper-padding {
  padding-left: 5%;
  padding-right: 5%;
}

%wrapper-margin {
  margin-left: 5%;
  margin-right: 5%;
}

%border-bottom-animation {
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0px;
    background: lightgrey;
    transition: height 0.3s;
  }
  &:hover::after {
    height: 3px;
  }
}
