@import '_theme';

.modalForm {
  font-family: $nunito;
  margin-top: 20px;
  input {
    &::placeholder {
      font-size: 14px;
    }
  }

  &__inputError {
    color: red;
    line-height: 20px;
  }

  &__inputWrapper {
    &:not(:first-of-type) {
      margin-top: 25px;
    }
  }

  &__inputWrapper--dual {
    display: flex;
    justify-content: space-between;
    div:first-of-type {
      margin-right: 10px;
    }
  }

  &__buttonWrapper {
    margin: auto;
    margin-top: 25px;
    margin-bottom: 10px;
    text-align: center;
    button:disabled,
    button[disabled] {
      pointer-events: none;
      border: 1px solid lighten($mainButtonColor, 30%);
      background-color: lighten($mainButtonColor, 30%);
      color: white;
    }
    button {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.4px;
      text-align: center;
      color: #ffffff;
      width: 100%;
      height: 47px;
      border-radius: 4px;
      background-color: $mainButtonColor;
      border: solid 1px $mainButtonColor;
      cursor: pointer;
      &:hover {
        color: white;
        font-weight: bold;
        background: darken($mainButtonColor, 10%);
      }
    }
  }

  &__buttonGroupWrapper {
    display: flex;
    justify-content: space-between;
    button {
      width: 140px;
    }
  }

  &__sendRequestLabel {
    font-size: 12px;
    color: grey;
  }
}

.profileForm {
  display: grid;
  grid-column: span 3;
  grid-gap: 30px;
  padding-top: 50px;
  padding-left: 50px;
  padding-bottom: 12px;

  &__buttonText {
    user-select: none;
    &:hover {
      svg {
        transform: translateX(2px);
      }
    }
    &:not(:first-of-type) {
      margin-bottom: 1em;
    }
    cursor: pointer;
    color: $mainButtonColor;
    justify-self: start;
    align-self: flex-start;
    font-size: 18px;
    padding-right: 88px;
    display: flex;
    align-items: flex-end;
    svg {
      padding-top: 5px;
      transition: transform 200ms ease-out;
    }
  }

  &__inputWrapper {
    flex-grow: 1;
    label {
      text-transform: uppercase;
      font-size: 13px;
      color: grey;
      letter-spacing: 0.4px;
    }
    input {
      box-sizing: border-box;
      margin-top: 3px;
      outline: none;
      width: 100%;
      font-size: 18px;
      padding-bottom: 5px;
      color: $mainFontColor;
      font-weight: 600;
      border: none;
      border-bottom: 1px solid grey;
      &:focus {
        border-bottom: 1px solid red;
      }
      &:read-only,
      &:read-only:focus {
        border-bottom: 1px dashed lightgrey;
      }
    }
    textarea {
      box-sizing: border-box;
      border: none;
      font-family: 'Nunito', 'Roboto', sans-serif;
      font-weight: 600;
      color: $mainFontColor;
      font-size: 18px;
    }
    span {
      color: red;
    }
    &--organization {
      span {
        display: block;
        color: black;
        font-size: 18px;
      }
    }
  }

  &__userAddresses {
    margin-bottom: -1.5em;
    .MuiFormControlLabel-label {
      color: grey;
      transform: translateY(-1.5px);
      font-size: 12px;
    }
    .MuiFormControlLabel-root {
      margin-right: 0;
      margin-left: 10px;
    }
    .MuiCheckbox-root {
      transform: translateY(-6.5px);
    }
    .plusSignHolder {
      button {
        text-transform: none;
      }
      i {
        font-size: 1em;
      }
    }

    &__label {
      display: block;
      color: grey;
      font-size: 13px;
      margin-bottom: 10px;
    }

    &__wrapper {
      display: flex;
      position: relative;
      .deleteRowIconHolder {
        position: absolute;
        right: -2em;
        i {
          top: 1em;
        }
      }
      input,
      select {
        color: black;
      }
    }
  }
}

.createRoleForm {
  height: 100%;
  overflow: hidden;
  margin-top: 10px;
  &__timePickerHolder {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    & > div {
      white-space: normal !important;
      max-width: 50% !important;
    }
  }
  &__dropdownHolder {
    margin: 10px 0;
    margin-bottom: 30px;
  }
}

.inputScheduleForm {
  margin-top: 5px;
  overflow: unset !important;

  &__nameDropdown {
    margin-top: 10px;
    margin-bottom: 5px;
    .MuiPaper-root {
      right: unset !important;
    }
  }

  .modalForm__buttonWrapper {
    width: 340px;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 0px;
  }
  .modalForm__nameTimeWrapper {
    display: flex;
    position: relative;
    .modalForm__time {
      display: flex;
      margin-top: 12px;
      margin-bottom: 8px;
    }
    .modalForm__name {
      margin-top: 2px;
      flex-basis: 80%;
      min-width: 210px;
      margin-right: 16px;
      .contentLoader {
        padding-top: 22px;
      }
    }
  }
}

.plusSignHolder {
  margin-top: 10px;
  color: grey;
  font-size: 16px;
  i {
    cursor: pointer;
    &:hover {
      color: $mainFontColor;
    }
  }
}

.deleteRowIconHolder {
  color: grey;
  position: relative;
  margin-left: 3px;
  user-select: none;
  i {
    position: relative;
    font-size: 20px;
    top: 36px;
    margin-left: 5px;
    cursor: pointer;
    &:hover {
      color: $mainFontColor;
    }
  }
}

.disableSelectIcon {
  .MuiNativeSelect-icon {
    display: none;
  }
}
