@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import './assets/styles/_theme.scss';
@import './assets/styles/_common.scss';

.clearfix:before,
.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  overflow: hidden;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  zoom: 1;
} /* IE < 8 */

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  * {
    box-sizing: border-box;
  }
}

// TODO: dark mode toggle
// const userPrefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
// const userPrefersLight = window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches;
// e.g. @media (prefers-color-scheme: dark) {
.darkMode {
  filter: invert(1) hue-rotate(180deg);
  img,
  .MuiCheckbox-root {
    filter: invert(1) hue-rotate(180deg);
  }
}

.App {
  // overflow-x: hidden;
  font-family: $nunito;
  min-height: 100vh;
  height: 100%;
}

.networkErrorHolder {
  font-size: 18px;
  padding-top: 5%;
  @extend %wrapper-margin;
}

.appContent {
  min-width: 968px;
  padding-top: 20px;
  min-height: calc(100vh - #{$globalHeaderHeight});
  background: $mainBackgroundColor;
  height: auto;
}

.Toast-Container {
  min-height: 40px;
  border-radius: 10px;
}

.not-allowed {
  cursor: not-allowed;
}

.ReactModal__Body--open {
  .globalHeader {
    z-index: 0 !important;
  }
}

.MuiTooltip-popper {
  z-index: 9 !important;
  * {
    font-size: 14px !important;
  }
}

.chevronHolder {
  display: inline-flex;
  position: relative;
  align-items: center;
  svg {
    transition: transform 200ms ease-in-out;
  }
}

.loadSprinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 3px solid lightgrey;
  border-top: 3px solid grey;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  animation: spin 1.5s linear infinite;
}

.flashAnimation {
  color: $mainTealColor !important;
  border: 1px solid $mainTealColor !important;
  svg {
    color: $mainTealColor !important;
  }
  animation: flash linear 1s infinite;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* blur all body */
body.ReactModal__Body--open.blured {
  overflow: hidden;
  position: relative;
}

body.ReactModal__Body--open.blured #root {
  position: absolute;
  user-select: none;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  filter: blur(7px);
}

/* calendar picker styles override */
.DayPicker {
  text-align: center !important;
  margin: 0 auto !important;
  background-color: rgb(250, 250, 250) !important;
}

.CalendarMonth {
  padding: 0 10px;
  border-radius: 5px;
  box-shadow: rgba(204, 204, 204, 0.2) 0px 1px 8px 0px, rgba(204, 204, 204, 0.14) 0px 3px 4px 0px,
    rgba(204, 204, 204, 0.12) 0px 3px 3px -2px;
}

.CalendarMonthGrid {
  background-color: transparent;
}

.CalendarMonth_table td,
.CalendarDay:hover {
  border: none;
}

.CalendarDay__selected,
.CalendarDay__selected:hover,
.CalendarDay__selected:focus {
  background: $mainButtonColor;
  border-radius: 50px;
  border: none;
}

.calendar__extraInfo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: #212b36;
  user-select: none;
  span {
    margin-right: 2px;
    display: inline-block;
    font-size: 10px;
    width: 12px;
    height: 12px;
    padding: 5px;
    box-sizing: content-box;
  }
  &__selectedDate {
    span {
      border-radius: 12px;
      color: $mainButtonColor;
      background-color: $mainButtonColor;
    }
  }
  &__unavailableDate {
    span {
      color: dimgrey;
      border: solid 1px lightgrey;
      background-color: lightgrey;
    }
  }
  &__availableDate {
    span {
      border: solid 1px lightgrey;
      background-color: white;
    }
  }
}

.toast-message {
  position: initial !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: fit-content;
  height: 48px;
  color: #fff !important;
  box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  margin: auto;
}

.toast-message.toast-success {
  background: $mainTealColor !important;
}

.toast-message.toast-discard {
  background: $mainFontColor !important;
}

.toast-dir-message.toast-discard {
  background: $mainFontColor !important;
}
