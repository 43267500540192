@import '_theme';

/* modals */
.modal__overlay {
  position: fixed;
  z-index: 2;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}

%modal-shared {
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  .modal__header {
    padding-right: 16px;
    text-align: left;
    font-family: $nunito;
    font-size: 24px;
    font-weight: bold;
    color: $mainButtonColor;
  }
  .close {
    transform: translateY(-8px);
  }
}

.modal__inviteUser {
  @extend %modal-shared;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 100%;
  max-width: 660px;
  padding: 30px;
  max-height: calc(100% - 5%);

  .MuiPaper-root {
    margin: 5px 0;
    position: relative !important;
  }

  .modal__header {
    margin-bottom: 30px;
    span {
      font-size: 22px;
      font-weight: bold;
    }
  }

  .modalForm__buttonWrapper {
    max-width: 80%;
    margin: 0 auto;
    margin-top: 15px;
  }

  .modal__formSelectors {
    display: flex;
    line-height: 30px;
    user-select: none;
    margin-top: 20px;
    justify-content: space-around;
    div {
      width: 47%;
      text-align: center;
      font-size: 18px;
      padding-bottom: 10px;
    }
    .modal__formSelector__wrapper--default {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: $mainFontColor;
      border-bottom: 2px solid lightgrey;
      cursor: pointer;
    }
    .modal__formSelector__wrapper--selected {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #222222;
      border-bottom: 3px solid #00859a;
    }
  }
}

.modal__resetPassword {
  @extend %modal-shared;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  padding: 30px;
  .copy-password {
    padding-top: 20px;
    text-align: right;
    span {
      cursor: pointer;
      color: grey;
      font-weight: bold;
      font-size: 16px;
    }
  }
}

.modal__confirmation {
  @extend %modal-shared;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  padding: 30px;
  .modal__confirmation__statement {
    font-size: 22px;
    margin: 40px;
    color: $mainFontColor;
    justify-self: center;
    align-self: center;
    span {
      color: red;
    }
    .timeText {
      font-size: 16px;
    }
  }
  .modal__confirmation__buttons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    button {
      width: 200px;
    }
  }
}

.modal__createRole,
.modal__organizationSelect,
.modal__inputSchedule {
  @extend %modal-shared;
  background-color: rgb(250, 250, 250);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 560px;
  padding: 24px;
  .copy-password {
    padding-top: 20px;
    text-align: right;
    span {
      cursor: pointer;
      color: grey;
      font-weight: bold;
      font-size: 16px;
    }
  }
}

.modal__createRole {
  max-height: calc(100% - 5%);
  overflow: unset !important;
}

.modal__inputSchedule__multiUser {
  overflow: unset;
  .react-select__menuList {
    width: 460px;
  }
}

.modal__TOS {
  @extend %modal-shared;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  padding: 30px;
  color: $mainFontColor;

  .modal__header {
    margin-bottom: 1em;
  }

  .tosDescription {
    padding: 10px;
    user-select: none;
    font-size: 14px;
  }
  .modal__confirmation__buttons {
    display: flex;
    justify-content: flex-end;
    button:last-of-type {
      margin-left: 2em;
    }
  }

  .tosErrorMsg {
    color: red;
    padding: 10px;
    font-size: 14px;
  }
}

.modal__changeRegion {
  @extend %modal-shared;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 620px;
  padding: 24px;

  .close span {
    color: red;
  }
  .active {
    background-color: #00859a;
  }

  .title {
    padding-right: 16px;
    text-align: left;
    font-family: $nunito;
    font-size: 21px;
    font-weight: bold;
    color: $mainButtonColor;
  }
  .decription-container {
    margin-top: 12px;
    margin-bottom: 24px;
    padding: 0;
  }
  .description {
    font-family: $openSans;
    font-weight: 400;
    font-size: 16px;
    color: $mainFontColor;
  }

  ul {
    overflow-y: auto;
  }

  ul li {
    padding: 16px;
    gap: 10px;
    border: 1px solid #d8d8d8;
    display: flex;
    align-items: center;
    border-radius: 4px;

    &:active {
      opacity: 0.5;
    }
    &:hover {
      color: black;
      background-color: #f2f2f2;
    }
  }

  li.active {
    background: #f6f6f9;
  }

  li.nonActive {
    opacity: 0.5;
  }
}
