@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

/* font */
$nunito: 'Nunito', sans-serif;
$openSans: 'Open Sans', sans-serif;

/* color */
$mainButtonColor: #ff0043;
$mainBlueColor: #3569e4;
$mainGreenColor: #009689;
$mainTealColor: #37869b;

$inputLabelColor: #aeaeae;
$mainFontColor: #4a4a4a;
$darkenFontColor: #222222;

$mainBackgroundColor: #fafafa;
$secondaryBackgroundColor: #f2f2f2;

$watermelonLight: #ffd8dd;
$watermelonColor: #ff3e55;

$borderBottomLight: #d8d8d8;

$globalHeaderHeight: 60px;

:export {
  mainButtonColor: $mainButtonColor;
  mainBlueColor: $mainBlueColor;
  inputLabelColor: $inputLabelColor;
  darkenFontColor: $darkenFontColor;
  mainFontColor: $mainFontColor;
  mainGreenColor: $mainGreenColor;
  mainTealColor: $mainTealColor;
  mainBackgroundColor: $mainBackgroundColor;
  globalHeaderHeight: $globalHeaderHeight;
  borderBottomLight: $borderBottomLight;
  watermelonLight: $watermelonLight;
  watermelonColor: $watermelonColor;
}
